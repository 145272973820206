import React, { useState } from 'react';
import { ProjectsEndpoints } from '../../../Routes/api/Endpoints';
import Comment from './commentairesComponent/Comment';
import { selectData } from '../../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useDeleteDataMutation, usePutTaskMutation } from '../../../services/auth-services/AuthService';

const CardComments = ({ comments, filterFunc, fetchData, handleSubmitComment, replyText, setReplyText, listResponseComments }) => {
  const [deleteComment, { isLoading: isLoadingDeleteComment }] =
    useDeleteDataMutation();
  const [updateComment, { isLoading: isLoadingUpdateComment }] =
    usePutTaskMutation();
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const [replyCommentId, setReplyCommentId] = useState(null);
  const user = useSelector(selectData);
  const [showReplies, setShowReplies] = useState(null);
  const handleDelete = (commentId, isParent) => {
    deleteComment({
      endpoint: ProjectsEndpoints.deleteMicroTask,
      dataId: commentId,
    })
      .unwrap()
      .then(() => {
        if (isParent) {
          const childComments = listResponseComments.filter(
            (comment) => comment.parent_id === commentId
          );
          childComments.forEach((comment) => {
            deleteComment({
              endpoint: ProjectsEndpoints.deleteMicroTask,
              dataId: comment.id,
            }).unwrap();
          });
        }
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting comment:", error);
      });
  };

  const handleEdit = (commentId, commentText) => {
    setEditCommentId(commentId);
    setEditCommentText(commentText);
  };

  const handleUpdate = async (commentId) => {
    const formData = {
      id: commentId,
      message: editCommentText,
    };

    updateComment({ endpoint: ProjectsEndpoints.updateComment, task: formData })
      .unwrap()
      .then(() => {
        setEditCommentId(null);
        setEditCommentText("");
        fetchData();
      })
      .catch((error) => {
        console.error("Error updating comment:", error);
      });
  };

  const handleCancel = () => {
    setEditCommentId(null);
    setEditCommentText("");
  };

  const handleReplySubmit = (commentId) => {
    handleSubmitComment({ preventDefault: () => {} }, commentId);
    setReplyCommentId(null);
    setReplyText("");
  };

  const handleCancelReply = () => {
    setReplyCommentId(null);
    setReplyText("");
  };

  // Afficher les réponses
  const toggleReplies = (commentId) => {
    setShowReplies(commentId);
  };

  return (
    <div className="flex flex-col gap-4">
      {comments?.map((elem, i) => (
        (!showReplies || showReplies === elem.id) && (
        <Comment
          key={i}
          elem={elem}
          filterFunc={filterFunc}
          editCommentId={editCommentId}
          editCommentText={editCommentText}
          setEditCommentText={setEditCommentText}
          handleUpdate={handleUpdate}
          isLoadingUpdateComment={isLoadingUpdateComment}
          handleCancel={handleCancel}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          isLoadingDeleteComment={isLoadingDeleteComment}
          listResponseComments={listResponseComments}
          replyCommentId={replyCommentId}
          setReplyCommentId={setReplyCommentId}
          replyText={replyText}
          setReplyText={setReplyText}
          handleReplySubmit={handleReplySubmit}
          handleCancelReply={handleCancelReply}
          user={user}
          showReplies={showReplies}
          setShowReplies={toggleReplies}
        />)
      ))}
    </div>
  );
};

export default CardComments;