import React from 'react'
import MonBouton from '../Utils/design-system/buttons/MonBouton';

const SelectCategory = ({
  category,
  id,
  handleSelectCategory,
  widthContainer,
}) => {
  console.log(category);
  const data = [
    { name: "Tous les templates", id: "" },
    { name: "Landing page", id: "landing_page" },
    { name: "Dashboard", id: "dashboard" },
  ];

  const data_projets = [
    { name: "Tous les projets", id: 1 },
    { name: "Programmation" },
    { name: "Design" },
    { name: "Marketing Digital" },
    { name: "Gestion de projet" },
  ];

  return (
    <div className={`flex gap-3 w-1/3 py-6 ${widthContainer}`}>
      {id === "templates"
        ? data?.map((cat, i) => (
            <MonBouton
              type="button"
              couleurFond={`${
                cat.id === category ? "bg-orange text-white" : "bg-blc text-graymat"
              } !max-w-max py-2 px-3 rounded-full font-mandali justify-center text-sm max-xl:text-xs max-lg:text-[8px] max-lg:py-1`}
              key={i}
              action={() => handleSelectCategory(cat.id)}
            >
              {cat.name}
            </MonBouton>
          ))
        : data_projets?.map((cat, i) => (
            <MonBouton
              type="button"
              couleurFond={`${
                cat.id ? "bg-orange text-white" : "bg-blc text-graymat"
              } !max-w-max py-2 px-3 rounded-full font-mandali justify-center text-sm max-xl:text-xs max-lg:text-[8px] max-lg:py-1`}
              key={i}
            >
              {cat.name}
            </MonBouton>
          ))}
    </div>
  );
};

export default SelectCategory
