import React from 'react';
import B1 from '../../assets/B1.svg';
import SVG from '../../assets/SVG.svg';
import B2 from '../../assets/B2.svg';
import B4 from '../../assets/B4.svg';
import B5 from '../../assets/B5.svg';
import B6 from '../../assets/B6.svg';

const Partenaires = () => {
    const services = [
        {
            name: "Conception graphique",
            icon: B1, 
            items: ["Logo", "Affiche", "Flyers", "Carte de visite"],
            listClass: "text-xs text-[#A6A6A6] mt-[-20px] mr-[70px] space-y-1 pl-8", 
            iconStyle: { width: '40px', height: '40px' } 
        },
        {
            name: "Création de contenu vidéo",
            icon: SVG,
            items: ["Tournage", "Montage"],
            listClass: "text-xs text-[#A6A6A6] mt-[-20px] mr-[135px] space-y-1 pl-8",
            iconStyle: { width: '25px', height: '25px' } 
        },
        {
            name: "Développement web",
            icon: B2,
            items: ["Landing page", "Site vitrine", "Micro application web"],
            listClass: "text-xs text-[#A6A6A6] mt-[-20px] mr-[85px] space-y-1 pl-8",
            titleStyle: { marginRight: '70px' },
            iconStyle: { width: '40px', height: '40px' } 
        },
        {
            name: "Développement mobile",
            icon: B4,
            items: ["Micro application mobile"],
            listClass: "text-xs text-[#A6A6A6] mt-[-20px] space-y-1 pl-8 mr-[30px]",
            cardClass: "mb-14",
            iconStyle: { width: '40px', height: '40px' } 
        },
        {
            name: "Développement logiciel",
            icon: B5,
            items: ["Mini logiciel"],
            listClass: "text-xs text-[#A6A6A6] mr-[155px] mt-[-20px] space-y-1 pl-8",
            titleStyle: { marginRight: '40px' },
            iconStyle: { width: '40px', height: '40px' }
        },
        {
            name: "Rédaction de contenu web",
            icon: B6,
            items: ["Article de blog", "Post sur les réseaux sociaux"],
            listClass: "text-xs text-[#A6A6A6] mt-[-20px] space-y-1 pl-8 mr-[50px]",
            titleStyle: { marginRight: '10px' },
            iconStyle: { width: '40px', height: '40px' } 
        },
    ];

    return (
        <div className="flex flex-col gap-6 w-[95%] pt-12 pb-16">
            <h1 className="text-4xl font-bold text-center text-noirel max-sm:text-lg max-lg:text-xl mb-0">
                Services Disponibles
            </h1>
            <p className="font-mandali font-normal text-base leading-6 text-center text-[#A6A6A6]">
                La plateforme Bakeli Work propose une variété de services digitaux pouvant être découpés
                <br className="block my-2" />
                en micro tâches
            </p>

            {/* Cartes de services */}
            <div className="grid grid-cols-3 gap-8 max-lg:grid-cols-2 max-md:grid-cols-1 max-md:gap-2 w-[100%]">
                {services.map((service, index) => (
                    <div key={index} className="flex flex-col items-center p-4 gap-4 mb-4">
                        <div className="flex items-center mb-2">
                            <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#FFEACB]">
                                {/* Utilisation des styles d'icône spécifiques */}
                                <img 
                                    src={service.icon} 
                                    alt={`${service.name} icon`} 
                                    style={service.iconStyle} 
                                />
                            </div>

                            <h1 
                                style={{ ...service.titleStyle, color: '#1F2937' }} 
                                className="font-semibold leading-[42px] text-left text-[#1F2937] text-[21.25px] weight-[500] ml-2 whitespace-nowrap">
                                {service.name}
                            </h1>
                        </div>

                        <ul className={`list-disc ${service.listClass}`}>
                            {service.items.map((item, itemIndex) => (
                                <li key={itemIndex} className="text-xs h-[68] mt-2 text-[#4B5563]">{item}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Partenaires;
