import React from "react";
import { FaTrophy } from "react-icons/fa";
import b4 from '../../assets/b4.png';
import b2 from '../../assets/b2.png';
import b3 from '../../assets/b3.png';
import b from '../../assets/b.png';
import { FaRegMessage } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import SVG from '../../assets/SVG.png';
import { CiGift } from "react-icons/ci";
// Composant pour les icônes
const Icone = ({ icon, color }) => (
  <div className={`rounded-full flex items-center justify-center ${color}`}>
    {icon}
  </div>
);

// Composant pour les cartes
const Carte = ({ bgColor, icon, titre, texte, textPosition, width }) => (
  <div className={`flex flex-col items-center p-4 rounded-xl shadow-md ${bgColor}`}>
    <Icone icon={icon} color={width} />
    <h3 className="font-semibold mt-2 text-black">{titre}</h3>
    <p className={`text-[#4B5563] mt-1 text-sm ${textPosition}`}>{texte}</p>
  </div>
);

// Composant pour les sections avec des titres
const SectionTitre = ({ grandTitre }) => (
  <div className="text-center my-8">
    <h2 className="text-2xl font-semibold text-noirel">{grandTitre}</h2>
  </div>
);

const Gamification = () => {
  return (
    <div className="container w-[90%] mx-auto px-4 py-12">
      {/* Titre principal */}
      <h1 className="text-[2.8rem] max-xl:text-4xl max-lg:text-xl antialiased font-semibold font-inter text-noirel max-sm:text-lg text-center mt-8">
        Système de Gamification Bakeli
      </h1>

      {/* Section des badges */}
      <SectionTitre className="text-xs" grandTitre="Système de Badges" />

      {/* Cartes des badges */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-8">
        <Carte
          bgColor="!py-6 !rounded-xl"
          icon={
            <img src={b} alt="Bronze Badge" className="size-16 rounded-full" />
          }
          titre="Bronze"
          texte="5+ tâches complétées"
        />
        <Carte
          bgColor="!py-6 !rounded-xl"
          icon={
            <img src={b2} alt="Bronze Badge" className="size-16 rounded-full" />
          }
          titre="Argent"
          texte="15+ tâches complétées"
        />
        <Carte
          bgColor="!py-6 !rounded-xl"
          icon={
            <img src={b3} alt="Bronze Badge" className="size-16 rounded-full" />
          }
          titre="Or"
          texte="30+ tâches complétées"
        />
        <Carte
          bgColor="!py-6 !rounded-xl"
          icon={
            <img
              src={b4}
              alt="Bronze Badge"
              className="size-16  rounded-full"
            />
          }
          titre="Platine"
          texte="50+ tâches complétées"
        />
      </div>
      {/********************************************************** */}
      {/* Classements et Palmarès */}

      <SectionTitre grandTitre="Classements et Palmarès" />
      {/* Cartes des classements */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-8">
        <Carte
          bgColor="!p-6"
          width={"!w-full"}
          icon={
            <div className="flex items-center gap-2 whitespace-nowrap w-full">
              <FaTrophy className="text-yellow-500 size-6" />
              <h3 className="font-semibold ml-2 text-black">
                Classements Hebdomadaires
              </h3>
            </div>
          }
          texte="Basés sur le nombre et la qualité des tâches terminées chaque semaine"
        />
        <Carte
          bgColor="!p-6"
          width="!w-full"
          icon={
            <div className="flex items-center gap-2 whitespace-nowrap w-full">
              <CiStar className="text-blue-600 text-3xl " />
              <h3 className="font-semibold ml-2 text-black">
                Classement par Compétences
              </h3>
            </div>
          }
          texte="Mesurez-vous aux autres dans votre domaine d'expertise spécifique"
        />
        <Carte
          bgColor="!p-6"
          width={"w-full"}
          icon={
            <div className="flex items-center gap-2 whitespace-nowrap w-full">
              {
                <img
                  src={SVG}
                  alt="Bronze Badge"
                  className="size-7 rounded-full"
                />
              }

              <h3 className="font-semibold text-black">Challenges Spéciaux</h3>
            </div>
          }
          texte="Compétitions mensuelles avec badges exclusifs pour les meilleurs Bakelistes"
        />
      </div>

      {/********************************************************** */}
      {/* Système de Points */}
      <SectionTitre grandTitre="Système de Points" />

      <div className="bg-white shadow-md p-4 mt-8 flex flex-col justify-center h-[160px] rounded-xl">
        <div className="flex justify-between">
          <div className="text-center w-[30%] flex flex-col gap-2">
            <h3
              className="text-2xl font-semibold text-orange-500 "
              style={{ color: "orange" }}
            >
              10pts
            </h3>
            <p className="text-gray-500 mt-0 text-sm ">Tâches Simples</p>
          </div>
          <div className="text-center w-[30%] flex flex-col gap-2">
            <h3
              className="text-2xl font-semibold text-orange-500 mx-2"
              style={{ color: "orange" }}
            >
              20pts
            </h3>
            <p className="text-gray-500 mt-0 text-sm ">Tâches Moyennes</p>
          </div>
          <div className="text-center w-[30%] flex flex-col gap-2">
            <h3
              className="text-2xl font-semibold text-orange-500 mx-2"
              style={{ color: "orange" }}
            >
              50pts
            </h3>
            <p className="text-gray-500 mt-0 text-sm">Tâches Complexes</p>
          </div>
        </div>

        <div className="text-center text-sm mt-4 text-gray-500">
          Gagner des points en terminant des tâches, en recevant des retours
          positifs et en respectant les détails.
        </div>
      </div>

      {/********************************************************** */}
      {/* Récompenses */}
      <SectionTitre grandTitre="Récompenses" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-8">
        <Carte
          width="w-full"
          textPosition="w-full"
          bgColor={"bg-white !p-6"}
          icon={
            <div className="flex gap-2 justify-start items-center w-full">
              <CiGift
                className="text-red-500 size-6"
                style={{ color: "red" }}
              />
              <h3 className=" font-semibold text-black whitespace-nowrap">
                Récompenses Tangibles
              </h3>
            </div>
          }
          texte="Bon d'achat, formations gratuites et primes financières pour les niveaux supérieurs"
        />
        <Carte
          width="w-full"
          textPosition="w-full"
          bgColor={"bg-white !p-6"}
          icon={
            <div className="flex gap-2 justify-start items-center w-full">
              <CiStar className="text-yellow-600 size-6" />
              <h3 className=" font-semibold text-black whitespace-nowrap">
                Accès Exclusif
              </h3>
            </div>
          }
          texte="Tâches premium et meilleure visibilité pour les badges Or et Platine"
        />
      </div>

      {/********************************************************** */}
      {/* Boucle de Feedback */}
      <SectionTitre grandTitre="Boucle de Feedback" />

      <div className="bg-white rounded-lg shadow-md p-6 text-center mt-8">
        <div className="flex flex-col items-center">
          <FaRegMessage className="text-green-500 text-4xl" />
          <p className="text-[#4B5563] mt-2 text-sm">
            Les évaluations des coachs et des clients influencent votre
            classement et l'acquisition de badges. Un feedback constructif
            assure une amélioration continue et maintient la motivation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Gamification;
