import React, { useState } from 'react';
import MonBouton from '../../Utils/design-system/buttons/MonBouton';
import { useModal } from '../../../context/ModalContext';
import ModalElement from '../../Utils/ModalElement';
import { ProjectsEndpoints } from '../../../Routes/api/Endpoints';
import CarouselModal from '../../Utils/CarouselModal';
import { useAppContext } from '../../../context/AppContext';
import { selectData } from '../../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useGetDataQuery, usePutTaskMutation } from '../../../services/auth-services/AuthService';
import toast from 'react-hot-toast';

const ProjectMember = ({ profileLetters, members, projectId, projectParticipants, refreshData, options }) => {
  const { isModalMembersOpen, closeModalProjectMember, modalTask, openModalProjectMember } = useModal();
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const user = useSelector(selectData)
  const { data: participants = [], refetch } = useGetDataQuery({
    endpoint: ProjectsEndpoints.getParticipants,
    projectId: projectId
  });
  const {closeModalConfirm, isOpenModalConfirm, openModalConfirm} = useModal();
  const isMember = (rejoignantId) => projectParticipants && projectParticipants.length > 0 && projectParticipants?.includes(rejoignantId);
  const [postules, { isLoading }] = usePutTaskMutation();
  const { apiUrlImg } = useAppContext();
  // console.log(options);
  

  const handleSelectedMember = async(optionId) => {
      const formData = {
        id: optionId,
        projetId: projectId
    };
    
    // console.log(formData?.id);
    // console.log(formData?.projetId);
    
    
  
      // if (!isMember(optionId)) {
        postules({ endpoint: ProjectsEndpoints.updateParticipants, task: formData })
        .unwrap()
        .then(() => {
          refetch();
          refreshData();
          closeModalConfirm();
        })
          .catch((error) => {
          toast.error(error?.message);
          console.log(error);  
        });
      // }
  };
  
  const handleOpenModalConfirm = (memberId) => {
    if (!isMember(memberId)) {
      setSelectedMemberId(memberId);
      openModalConfirm();
    } 
  };

  return (
    <div className="flex items-center gap-2">
      <h2 className='font-mandali font-semibold text-noir_300'>
        Membres du projet
      </h2>
      <div className="relative">
        <ul className="list-none flex items-center -space-x-2">
          {participants?.participants?.map((member, i) => {
            const firstLetters = profileLetters(member.name);
            
            return (
              <li key={i} className="relative z-[calc(10-i)]">
                {!member.profile ? (
                  <span
                    className="profile cursor-pointer tooltip flex items-center justify-center w-9 h-9 text-xs rounded-full border bg-bl text-blanc font-medium"
                    data-tip={member.name}
                  >
                    {firstLetters}
                  </span>
                ) : (
                  <span className="tooltip" data-tip={member.name}>
                    <img
                      src={`${apiUrlImg}/${member?.profile}`}
                      className="w-9 h-9 rounded-full object-cover"
                    />
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {user.statut === "coach" && (
        <span className="tooltip indicator" data-tip="Ajouter des membres">
          <MonBouton
            type={"button"}
            children={"Membres"}
            couleurFond={"bg-orange text-white"}
            bordure={
              "max-w-max rounded-badge items-center text-xs font-inter py-2 px-4 justify-center focus:border-none"
            }
            action={() => openModalProjectMember()}
            icone={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="0.9em"
                height="0.9em"
                viewBox="0 0 1920 1792"
              >
                <path
                  fill="currentColor"
                  d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256m1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5M640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181m704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640m576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353m-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181"
                ></path>
              </svg>
            }
          />
          <span className="badge badge-xs border border-white_100 badge-error indicator-item top-1 right-2"></span>
        </span>
      )}
      {isModalMembersOpen && (
        <ModalElement
          titleModal={"Membres voulant rejoindre le projet"}
          task={modalTask}
          onClose={closeModalProjectMember}
          membres={members}
          isLoading={isLoading}
          handleAssign={() => console.log("clicked")}
          checkMember={true}
          profileLetters={profileLetters}
          options={options}
          isMember={isMember}
          participants={participants}
          handleSelectedMember={handleOpenModalConfirm}
        />
      )}
      {isOpenModalConfirm && (
        <CarouselModal
          onClose={closeModalConfirm}
          modalConfirm={true}
          actionText={"accepter cette demande"}
          validation={true}
          title={"Validation"}
          actionConfirm={() => handleSelectedMember(selectedMemberId)}
          loader={isLoading}
          actionAnnuler={closeModalConfirm}
        />
      )}
    </div>
  );
}

export default ProjectMember;