import { LuClock } from "react-icons/lu";
import taskImage from "../../assets/Ellipse 35.png";
import { FaRegUser, FaUserPlus } from "react-icons/fa6";
import { HiOutlineUsers } from "react-icons/hi";
import { FaCalendarAlt, FaCheckCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import imageProfile from "../../assets/images/avatar-2.webp"
export const taskTab = [
    {description: "Créé un logo pour un site web", budget:"50.000FCFA", dateLine: "25/09/2025", nbreDay:"Il y'a 4 jours", image: taskImage, id: 1},
    {description: "Créé un logo pour un site web", budget:"50.000FCFA", dateLine: "25/09/2025", nbreDay:"Il y'a 4 jours", image: taskImage, id: 2},
    {description: "Créé un logo pour un site web", budget:"50.000FCFA", dateLine: "25/09/2025", nbreDay:"Il y'a 4 jours", image: taskImage, id: 3},
    {description: "Créé un logo pour un site web", budget:"50.000FCFA", dateLine: "25/09/2025", nbreDay:"Il y'a 4 jours", image: taskImage, id: 4},
    {description: "Créé un logo pour un site web", budget:"50.000FCFA", dateLine: "25/09/2025", nbreDay:"Il y'a 4 jours", image: taskImage, id: 5},
    {description: "Créé un logo pour un site web", budget:"50.000FCFA", dateLine: "25/09/2025", nbreDay:"Il y'a 4 jours", image: taskImage, id: 6},
]

export const contentBoutonList = [
  {
    text: "Postuler",
    icon: <FaRegUser size={"12px"} />,
    title: "Suggérées",
    width: "max-2xl:w-[55%] max-lg:w-[35%] max-md:w-[65%]",
    padding: "px-10",
    postule: true,
  },
  {
    text: "Dates",
    icon: <FaCalendarAlt size={"14px"} />,
    width: "max-2xl:w-[55%] max-lg:w-[35%] max-md:w-[65%]",
    padding: "",
    dates: true,
  },
  {
    text: "Assigner",
    icon: <FaUserPlus size={"16px"} />,
    title: "Ajout au projet",
    width: "max-2xl:w-[55%] max-lg:w-[35%] max-md:w-[65%]",
    padding: "",
    members: true,
  },
];

export const data = [
  {
    slug: "bakeli-work-2",
    name: "Mamadou Barry",
    date: "1",
    profile: imageProfile,
    message:
      "a postulé sur la tache Intégration dashboard du projet Bakeli Work",
  },
];

export const footerItems = [
  // {
  //   linkOne: {
  //     icon: <IoMail size={22} color="#4A4E68" radius={"14px"} />,
  //     text: "guindyma@gmail.com",
  //   },
  //   linkTwo: {
  //     icon: <FaPhone size={20} color="#4A4E68" />,
  //     text: "+221 77 000 00 00",
  //   },
  // },
  {
    linkOne: { text: "Support Center", path: "#" },
    linkTwo: { text: "Customer Support", path: "#" },
    linkThree: { text: "About Us", path: "#" },
    linkFour: { text: "Popular Campaign", path: "#" },
    title: { text: "About Us" },
  },
  {
    linkOne: { text: "Return Policy", path: "#" },
    linkTwo: { text: "Privacy Policy", path: "#" },
    linkThree: { text: "Terms & Conditions", path: "#" },
    linkFour: { text: "Site Map", path: "#" },
    linkFive: { text: "Store Hours", path: "#" },
    title: { text: "Our Information" },
  },
  {
    linkOne: { text: "Press Inquiries", path: "#" },
    linkTwo: { text: "Social Media Directories", path: "#" },
    linkThree: { text: "Images & B-roll", path: "#" },
    linkFour: { text: "Permissions", path: "#" },
    linkFive: { text: "Speaker Requests", path: "#" },
    title: { text: "My Account" },
  },
  {
    linkOne: { text: "Application Security", path: "#" },
    linkTwo: { text: "Softwere Principles", path: "#" },
    linkThree: { text: "Unwanted Softwere Policy", path: "#" },
    linkFour: { text: "Risponsible Supply Chain", path: "#" },
    title: { text: "Policy" },
  },
];