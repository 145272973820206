import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { IoIosSend } from "react-icons/io";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { PropagateLoader, PulseLoader } from "react-spinners";
import { selectData } from "../../../redux/features/userSliceWithTokenValidation";
import { ProjectsEndpoints } from "../../../Routes/api/Endpoints";
import { usePostDataMutation } from "../../../services/auth-services/AuthService";
import MonBouton from "../../Utils/design-system/buttons/MonBouton";
import MonInput from "../../Utils/design-system/inputs/MonInput";
import CardComments from "./CardComments";
import { BiError } from "react-icons/bi";

const Conversations = ({ task, filterFunc, comments, fetchData }) => {
  const [comment, setComment] = useState("");
  const [saisie, setSaisie] = useState(false);
  const [postData, { isLoading }] = usePostDataMutation();
  const [showAllComments, setShowAllComments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [replyText, setReplyText] = useState("");
  const user = useSelector(selectData);
  const listComments = comments?.commentaires?.filter(
    (elem) => !elem?.parent_id
  );
  const listResponseComments = comments?.commentaires?.filter(
    (elem) => elem?.parent_id
  );
  
  const handleSubmitComment = (e, parentId = null) => {
    e.preventDefault();
    if (comment.trim() === "") {
      return setSaisie(true);
    } 
    const formData = new FormData();
    if (parentId) {
      formData.append("message", replyText);
      formData.append("parent_id", parentId);
      formData.append("commentateur_id", user?.id);
    } else {
      setLoading(true);
      formData.append("message", comment);
      formData.append("commentateur_id", user?.id);
    }

    postData({
      endpoint: ProjectsEndpoints.postCommentTask,
      elementID: task?.id,
      formData,
    })
      .unwrap()
      .then(() => {
        fetchData();
        setComment("");
        setReplyCommentId(null);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };
  const handleShowMoreComments = () => {
    setShowAllComments(!showAllComments);
  };
  const displayedComments = showAllComments
    ? listComments
    : listComments?.slice(0, 3);

  return (
    <div>
      <div className="w-full pt-6">
        <div className="flex gap-2 items-center">
          {/* <IoList size={"25px"} /> */}
          <h3 className="text-noir_300 text-xl font-bold">Messages</h3>
          <span className="text-xl font-normal text-grys">
            (
            {comments?.commentaires?.length
              ? comments?.commentaires?.length
              : 0}
            )
          </span>
        </div>
      </div>

      <div className="pt-6 w-full">
        <CardComments
          comments={displayedComments}
          filterFunc={filterFunc}
          fetchData={fetchData}
          setReplyCommentId={setReplyCommentId}
          handleSubmitComment={handleSubmitComment}
          replyText={replyText}
          listResponseComments={listResponseComments}
          setReplyText={setReplyText}
        />
        {comments?.commentaires?.length > 4 && (
          <div className="relative mt-3">
            <MonBouton
              children={showAllComments ? "Afficher moins" : "Afficher plus"}
              couleurFond={""}
              bordure={
                "px-2 md:w-14rem outline-none flex border rounded text-blk font-inter text-xs py-2 w-full"
              }
              action={handleShowMoreComments}
              type={"button"}
              alignment={"justify-center"}
              icone={
                !showAllComments ? (
                  <MdOutlineKeyboardArrowDown />
                ) : (
                  <MdOutlineKeyboardArrowUp />
                )
              }
            />
          </div>
        )}
      </div>
      {saisie && (
        <div className="bg-red-100 flex gap-3 px-5 py-2 bg-orange/15 justify-center items-center mt-2 rounded-xl">
          <div className="border-r-2 h-14 flex items-center pe-3 border-rg">
            <BiError className="text-4xl text-rg" />
          </div>
          <p className="text-rg text-start text-md font-bold italic ">
            Veuillez ecrire le message avant de soumettre !
          </p>
        </div>
      )}
      <div className="w-full flex flex-col gap-3">
        <MonInput
          type={"text"}
          id={"commentaire"}
          name={"commentaire"}
          value={comment}
          onChange={(e) => { setComment(e.target.value); setSaisie(false);}}
          placeholder={"Ajouter un commentaire..."}
          paddingB={
            "w-full bg-white pt-4 font-inter font-medium !px-2 pb-12 flex-wrap shadow-none rounded-md border border-grys/50 text-sm"
          }
          required={false}
        />
        <div className="inline-flex justify-end items-center w-full">
          <MonBouton
            children={
              loading ? <PulseLoader color="#fff" size={"8px"} /> : "Envoyer"
            }
            couleurFond={"bg-orange text-white"}
            bordure={
              `w-32 mb-4 font-inter ${loading? "py-3":'py-2'} justify-center rounded-full text-sm items-center`
            }
            type={"button"}
            action={handleSubmitComment}
            disabled={loading ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default Conversations;