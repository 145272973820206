import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthentificationEndpoints } from "../../Routes/api/Endpoints";
import { logout, selectToken, setAccessToken } from "../../redux/features/userSliceWithTokenValidation";
import { apiUrlCat } from "../../Routes/api/global_vars";

export const API = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrlCat,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState());
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      // headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ email, password }) => ({
        url: AuthentificationEndpoints.postUserLogin,
        method: "POST",
        body: { email, password },
      }),
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: AuthentificationEndpoints.logout,
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(logout());
      },
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: AuthentificationEndpoints.refreshToken,
        method: "POST",
      }),
      onSuccess: (response, { dispatch }) => {
        const { access } = response.data;
        dispatch(setAccessToken(access));
      },
    }),
    addProject: builder.mutation({
      query: ({ endpoints, formData }) => ({
        url: endpoints,
        method: "POST",
        body: formData,
      }),
    }),
    postData: builder.mutation({
      query: ({ endpoint, elementID, formData }) => ({
        url: `${endpoint}${elementID}`,
        method: "POST",
        body: formData,
      }),
    }),
    deleteData: builder.mutation({
      query: ({ endpoint, dataId }) => ({
        url: `${endpoint}${dataId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    putTask: builder.mutation({
      query: ({ endpoint, task }) => ({
        url: `${endpoint}${task?.id}`,
        method: "PUT",
        body: JSON.stringify(task),
        // headers: {
        //   "Content-Type": "application/json",
        // },
      }),
      // async onSuccess(_, { dispatch }) {
      //   // Ici vous pouvez ajouter la logique pour invalider les queries si nécessaire
      //   // await dispatch(someAction());
      // },
    }),
    fetchProjects: builder.query({
      query: (endpoints) => ({
        url: endpoints,
        method: "GET",
      }),
      transformResponse: (response) => response.projects,
    }),
    fetchNotifications: builder.query({
      query: (endpoints) => ({
        url: endpoints,
        method: "GET",
      }),
      transformResponse: (response) => response.notifications,
    }),
    getData: builder.query({
      query: ({ endpoint, projectId }) => {
        if (projectId) {
          return `${endpoint}${projectId}`;
        }
        return endpoint;
      },
    }),
    fetchCategories: builder.query({
      query: (onSelectCategory) => {
        if (!onSelectCategory) {
          return { url: "", method: "GET" };
        }
        return {
          url: `/categories-templates`,
          method: "GET",
        };
      },
    }),
  }),
});
  
  export const {
    useLoginUserMutation,
    useLogoutUserMutation,
    useRefreshTokenMutation,
    useAddProjectMutation,
    useFetchProjectsQuery,
    useGetDataQuery,
    usePostDataMutation,
    useDeleteDataMutation,
    usePutTaskMutation,
    useFetchCategoriesQuery,
    useFetchNotificationsQuery,
  } = API;
  
  export default API