import React, { useState } from 'react';
import { formatDateComplete, formatTime } from '../../../Utils/FormatDate'; 
import { Tooltip } from 'react-tooltip';
import EditableComment from './EditableComment';
import CommentOptions from './CommentOptions';
import ReplyForm from './ReplyForm'; 
import { useAppContext } from '../../../../context/AppContext';
import MonBouton from '../../../Utils/design-system/buttons/MonBouton';
import { BsArrowLeft } from 'react-icons/bs';

const Comment = ({ elem, filterFunc, editCommentId, editCommentText, setEditCommentText, handleUpdate, handleCancel, handleEdit, handleDelete, isLoadingDeleteComment, isLoadingUpdateComment, replyCommentId, setReplyCommentId, replyText, setReplyText, handleReplySubmit, handleCancelReply,
  listResponseComments, user, showReplies, setShowReplies
}) => {
    const firstLetters = filterFunc(elem.commentateur?.name);
    const responseComment = listResponseComments?.filter((comment) => comment?.parent_id === elem?.id)
  
    const {apiUrlImg} = useAppContext()  
    
    return (
      <div className="flex flex-col gap-4">
        {showReplies === elem.id && (
          <div className="card bg-white rounded w-full shadow-none py-3 px-4 cursor-pointer">
            <BsArrowLeft onClick={() => setShowReplies(null)} size={20} />
          </div>
        )}
        <div className="card bg-white rounded w-full shadow-none">
          <div className="card-body px-0 py-3 w-full">
            <div className="flex justify-between items-center px-4 pt-2">
              <div className="flex items-center text-sm font-inter text-noir_300 font-medium gap-2 ">
                {elem?.commentateur?.profile ? (
                  <span>
                    <img
                      src={`${apiUrlImg}/${elem?.commentateur?.profile}`}
                      className="w-8 h-8 rounded-full object-cover"
                    />
                  </span>
                ) : (
                  <span className="size-8 profile cursor-pointer text-center items-center font-normal text-sm flex justify-center rounded-full border bg-bl text-blanc">
                    {firstLetters}
                  </span>
                )}
                <span className="text-orange">{elem.commentateur?.name}</span>
              </div>
              <span className="text-xs font-medium text-noir_300 font-inter">
                {formatDateComplete(elem.created_at)}
              </span>
            </div>
            <p className="ps-6 w-11/12 font-inter text-[13px] font-light">
              {editCommentId === elem.id ? (
                <EditableComment
                  editCommentText={editCommentText}
                  setEditCommentText={setEditCommentText}
                  handleUpdate={handleUpdate}
                  isLoadingUpdateComment={isLoadingUpdateComment}
                  handleCancel={handleCancel}
                  commentId={elem.id}
                  replyCommentId={replyCommentId}
                  setReplyCommentId={setReplyCommentId}
                  replyText={replyText}
                  setReplyText={setReplyText}
                  handleReplySubmit={handleReplySubmit}
                  handleCancelReply={handleCancelReply}
                />
              ) : (
                <>{elem?.message}</>
              )}
            </p>
            <div className="card-actions flex-col border-t border-gryborder/50 pt-2 px-4">
              {replyCommentId === elem.id && (
                <ReplyForm
                  replyText={replyText}
                  setReplyText={setReplyText}
                  handleReplySubmit={() => handleReplySubmit(elem.id)}
                  handleCancelReply={handleCancelReply}
                />
              )}
              <div className="flex w-full justify-between items-center">
                <span>
                  <CommentOptions
                    commentId={elem.id}
                    commentText={elem.message}
                    handleEdit={() => handleEdit(elem.id, elem.message)}
                    handleDelete={handleDelete}
                    isLoadingDeleteComment={isLoadingDeleteComment}
                    handleReply={() => setReplyCommentId(elem.id)}
                    // toggleReplies={toggleReplies}
                    showReplies={showReplies}
                    user={user}
                    elem={elem?.commentateur_id}
                  />
                </span>
                {responseComment?.length > 0 && (
                  <div
                    className="flex gap-2 items-center cursor-pointer"
                    onClick={() => setShowReplies(elem?.id)}
                  >
                    <span className="text-xs font-medium text-noir_300 font-inter">
                      {responseComment?.length} réponse(s)
                    </span>
                    <div className="flex items-center -space-x-2">
                      {responseComment?.map((comment, i) => {
                        const firstLettersResponse = filterFunc(
                          comment?.commentateur?.name
                        );
                        return (
                          <span key={i} className="relative z-[calc(10-i)]">
                            {comment?.commentateur?.profile ? (
                              <span>
                                <img
                                  src={`${apiUrlImg}/${comment?.commentateur?.profile}`}
                                  className="w-6 h-6 rounded-full object-cover"
                                />
                              </span>
                            ) : (
                              <span className="size-8 profile cursor-pointer text-center items-center font-normal text-sm flex justify-center rounded-full border bg-bl text-blanc">
                                {firstLettersResponse}
                              </span>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showReplies === elem.id && responseComment?.length > 0 && (
          <div className="flex flex-wrap items-center justify-between">
            <span className="text-[10px] font-medium text-noir_300 font-inter">
              {responseComment?.length} réponse(s)
            </span>
            <hr className="border w-[82%]" />
          </div>
        )}
        {showReplies === elem.id &&
          responseComment?.map((comment, i) => {
            const firstLettersResponse = filterFunc(
              comment?.commentateur?.name
            );
            return (
              <div className="card bg-white rounded w-full shadow-none" key={i}>
                <div className="card-body px-0 py-3 w-full">
                  <div className="flex justify-between items-center px-4 pt-2">
                    <div className="flex items-center text-sm font-inter text-noir_300 font-medium gap-2 ">
                      {comment?.commentateur?.profile ? (
                        <span>
                          <img
                            src={`${apiUrlImg}/${comment?.commentateur?.profile}`}
                            className="w-8 h-8 rounded-full object-cover"
                          />
                        </span>
                      ) : (
                        <span className="size-8 profile cursor-pointer text-center items-center font-normal text-sm flex justify-center rounded-full border bg-bl text-blanc">
                          {firstLettersResponse}
                        </span>
                      )}
                      <span className="text-orange">
                        {comment.commentateur?.name}
                      </span>
                    </div>
                    <span className="text-xs font-medium text-noir_300 font-inter">
                      {formatDateComplete(comment.created_at)}
                    </span>
                  </div>
                  <p className="ps-6 w-11/12 font-inter text-[13px] font-light">
                    {editCommentId === comment?.id ? (
                      <EditableComment
                        editCommentText={editCommentText}
                        setEditCommentText={setEditCommentText}
                        handleUpdate={handleUpdate}
                        isLoadingUpdateComment={isLoadingUpdateComment}
                        handleCancel={handleCancel}
                        commentId={comment?.id}
                        replyCommentId={replyCommentId}
                        setReplyCommentId={setReplyCommentId}
                        replyText={replyText}
                        setReplyText={setReplyText}
                        handleReplySubmit={handleReplySubmit}
                        handleCancelReply={handleCancelReply}
                      />
                    ) : (
                      <> {comment?.message} </>
                    )}
                  </p>
                  <div className="card-actions justify-between items-center border-t pt-2 border-gryborder/50 px-4">
                    <span>
                      <CommentOptions
                        commentId={comment?.id}
                        commentText={comment?.message}
                        handleEdit={() =>
                          handleEdit(comment?.id, comment?.message)
                        }
                        reply={true}
                        handleDelete={handleDelete}
                        isLoadingDeleteComment={isLoadingDeleteComment}
                        handleReply={() => setReplyCommentId(comment?.id)}
                      />
                    </span>
                    <div className="flex gap-2 items-center">
                      <MonBouton
                        children={"Répondre"}
                        couleurFond={"text-noir_300"}
                        bordure={
                          "w-max text-xs font-medium text-blueclear font-inter justify-center items-center"
                        }
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
};

export default Comment;