import React, { useState } from 'react'
import Livraisons from './Livraisons';
import Conversations from './Conversations';
import CriteriaList from './CriteriaList';

const Tabs = ({ task, filterFunc, comments, fetchData, microTaches, refetchMicroTaches, profileLetters, name, tab, notifMicrotache }) => {
  const [activeTab, setActiveTab] = useState(tab);
  return (
    <div className="w-full flex flex-col">
      <ul className="list-none flex items-center justify-center gap-4 font-inter text-indigos font-normal w-full pt-12 border-b border-gryborder/60">
        <li
          className={`cursor-pointer text-center text-[13px] pt-1 pb-2 w-[28%] ${
            activeTab === 0 && "border-b-2 border-orange text-orange"
          }`}
          onClick={() => setActiveTab(0)}
        >
          Critères de validation
        </li>
        <li
          className={`flex justify-center cursor-pointer text-center pt-1 pb-2 text-[13px] w-[28%] indicator ${
            activeTab === 1 && "border-b-2 border-orange text-orange"
          }`}
          onClick={() => setActiveTab(1)}
        >
          Conversations
          <span className="badge badge-sm text-white badge-error indicator-item rounded-full py-2">
            1
          </span>
        </li>
        <li
          className={`cursor-pointer text-center text-[13px] pt-1 pb-2 w-[28%] ${
            activeTab === 2 && "border-b-2 border-orange text-orange"
          }`}
          onClick={() => setActiveTab(2)}
        >
          Livraisons
        </li>
      </ul>
      <div className='w-full flex justify-center'>
        <div className='w-[88%]'>
          {activeTab === 0 && (
            <CriteriaList
              task={task}
              microTaches={microTaches}
              refetchMicroTaches={refetchMicroTaches}
              notifMicrotache={notifMicrotache}
            />
          )}
          {activeTab === 1 && (
            <Conversations
              task={task}
              filterFunc={filterFunc}
              comments={comments}
              fetchData={fetchData}
            />
          )}
          {activeTab === 2 && (
            <Livraisons name={name} task={task} profileLetters={profileLetters} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Tabs
