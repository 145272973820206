import React from "react";
import { FaAngleDown, FaCheck } from "react-icons/fa6";
import { IoFilterOutline } from "react-icons/io5";
import { PulseLoader } from "react-spinners";
// import MonInput from "./design-system/inputs/MonInput";
import { useAppContext } from "../../context/AppContext";
import MonBouton from "./design-system/buttons/MonBouton";
import { IoMdContact } from "react-icons/io";

const ModalElementContent = ({
  members,
  loadingMember,
  isLoading,
  handleAssign,
  profileLetters,
  checkMember,
  handleSelectedMember,
  options,
  isMember,
  titleModal,
  memberAssigned,
  participants,
}) => {
  const { apiUrlImg } = useAppContext();
  const rejoignants = options?.filter(
    (option) =>
      !participants?.participants.some((participant) => participant.id === option.id)
  );
  
  return (
    <>
      {members && (
        <div className=" text-noir_300 font-medium">
          <h2 className="text-lg font-inter font-medium pt-6">{titleModal}</h2>
          <div className="pb-2 ps-2">
            {checkMember && rejoignants && rejoignants?.length > 0 ? (
              rejoignants?.map((option, index) => {
                const firstLetters = profileLetters(option?.name);

                return (
                  <li
                    className={`w-full flex items-center justify-between py-2 text-xs px-0`}
                    role="menuitem"
                    key={index}
                    onClick={() => handleAssign(option.id)}
                  >
                    <span className="flex items-center text-sm font-inter text-noir_300 font-semibold gap-2 mt-2">
                      {!option.profile ? (
                        <span className="profile cursor-pointer flex items-center justify-center size-10 text-xs rounded-full border bg-bl text-blanc font-medium">
                          {firstLetters}
                        </span>
                      ) : (
                        <div className="w-8 h-8 indicator">
                          <img
                            src={`${apiUrlImg}/${option?.profile}`}
                            className="w-full h-full rounded-full object-cover"
                          />
                          <span className=" rounded-full w-2 h-2  badge-error top-1 right-1 indicator-item border-grayclear"></span>
                        </div>
                      )}
                      <span>
                        {option.name}
                        <p className="text-[10px] font-normal">
                          {option?.email}
                        </p>
                      </span>
                    </span>
                    <div className="flex flex-wrap justify-end items-end gap-2">
                      <MonBouton
                        type={"button"}
                        children={"Refuser"}
                        couleurFond={"text-red"}
                        bordure={
                          "max-w-max rounded items-center text-xs font-inter font-bold cursor-text h-6 px-2 justify-center focus:border-none"
                        }
                        disabled={true}
                      />
                      <MonBouton
                        type={"button"}
                        children={"Accepter"}
                        couleurFond={"bg-orange text-white"}
                        bordure={`max-w-max rounded items-center cursor-pointer text-xs font-inter h-6 px-2 font-normal justify-center focus:border-none ${
                          isLoading && "text-[8px]"
                        }`}
                        action={() => handleSelectedMember(option.id)}
                      />
                    </div>
                  </li>
                );
              })
            ) : memberAssigned && members ? (
              members?.length > 0 &&
              members?.filter((member) => member?.id === memberAssigned).map((option, index) => {
                const firstLetters = profileLetters(option?.name);

                return (
                  <li
                    className={`w-full cursor-pointer flex mt-1 items-center justify-between py-2 text-xs pe-3`}
                    role="menuitem"
                    key={index}
                  >
                    <span className="flex items-center text-sm font-inter text-noir_300 font-semibold gap-2 mt-2">
                      {!option.profile ? (
                        <span className="profile cursor-pointer flex items-center justify-center size-10 text-xs rounded-full border bg-bl text-blanc font-medium">
                          {firstLetters}
                        </span>
                      ) : (
                        <span>
                          <img
                            src={`${apiUrlImg}/${option?.profile}`}
                            className="w-9 h-9 rounded-full object-cover"
                          />
                        </span>
                      )}
                      <span>
                        {option.name}
                        <p className="text-[10px] font-normal">
                          {option?.email}
                        </p>
                      </span>
                    </span>
                  </li>
                );
              })
            ) : (
              <span className="flex items-center text-sm font-inter text-noir_300 font-medium gap-2 pt-4">
                <div className=" indicator">
                  <IoMdContact size={46} className="text-gr" />
                  <span className=" rounded-full w-2 h-2  badge-error top-2 right-3 indicator-item border-grayclear"></span>
                </div>
                {checkMember
                  ? "Aucune demande disponible"
                  : "Aucun membre n'est assigné"}
              </span>
            )}
          </div>
          <div className="pt-6">
            <h6 className="text-lg font-inter font-bold">Membres</h6>
            <div className="pt-1 px-2">
              {!checkMember ? (
                members &&
                members?.filter((member) => member?.id !== memberAssigned).length > 0 ?
                members?.filter((member) => member?.id !== memberAssigned).map((option, index) => {
                  const firstLetters = profileLetters(option?.name);

                  return (
                    <li
                      className={`w-full cursor-pointer flex mt-1 items-center justify-between py-2 text-xs pe-3`}
                      role="menuitem"
                      key={index}
                    >
                      <span className="flex items-center text-sm font-inter text-noir_300 font-semibold gap-2 mt-2">
                        {!option.profile ? (
                          <span className="profile cursor-pointer flex items-center justify-center size-10 text-xs rounded-full border bg-bl text-blanc font-medium">
                            {firstLetters}
                          </span>
                        ) : (
                          <span>
                            <img
                              src={`${apiUrlImg}/${option?.profile}`}
                              className="w-9 h-9 rounded-full object-cover"
                            />
                          </span>
                        )}
                        <span>
                          {option.name}
                          <p className="text-[10px] font-normal">
                            {option?.email}
                          </p>
                        </span>
                      </span>
                      <MonBouton
                        type={"button"}
                        children={"Assigné"}
                        couleurFond={"bg-orange text-white"}
                        bordure={`max-w-max rounded items-center cursor-pointer text-xs font-inter h-6 px-2 font-normal justify-center focus:border-none ${
                          isLoading && "text-[8px]"
                        }`}
                        action={() =>
                          memberAssigned !== null
                            ? null
                            : handleAssign(option.id)
                        }
                      />
                    </li>
                  );
                }): <p className="text-center font-normal font-inter">Aucun postulants</p>
              ) : participants && participants?.participants.length > 0 ? (
                participants?.participants.map((option, index) => {
                  const firstLetters = profileLetters(option?.name);

                  return (
                    <li
                      className={`w-full cursor-pointer flex items-center justify-between py-2 text-xs`}
                      role="menuitem"
                      key={index}
                    >
                      <span className="flex items-center text-sm font-inter text-noir_300 font-semibold gap-2 mt-2">
                        {!option.profile ? (
                          <span className="profile cursor-pointer flex items-center justify-center size-10 text-xs rounded-full border bg-bl text-blanc font-medium">
                            {firstLetters}
                          </span>
                        ) : (
                          <span>
                            <img
                              src={`${apiUrlImg}/${option?.profile}`}
                              className="w-9 h-9 rounded-full object-cover"
                            />
                          </span>
                        )}
                        <span>
                          {option.name}
                          <p className="text-[10px] font-normal">
                            {option?.email}
                          </p>
                        </span>
                      </span>
                      <div className="dropdown dropdown-end">
                        <FaAngleDown tabIndex={0} role="button" size={14} />
                        <ul
                          tabIndex={0}
                          className="dropdown-content menu bg-white rounded items-center z-[1] w-24 shadow"
                        >
                          <li className="">
                            <MonBouton
                              type={"button"}
                              children={"Retirer"}
                              couleurFond={""}
                              bordure={`max-w-max rounded-none hover:!bg-transparent items-center !cursor-text text-xs font-inter font-medium text-center justify-center`}
                              action={() => handleSelectedMember(option.id)}
                              disabled={true}
                            />
                          </li>
                        </ul>
                      </div>
                    </li>
                  );
                })
              ) : (
                <p className="text-xs text-center py-3">
                  Aucune participant pour le moment
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalElementContent;

