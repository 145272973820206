import React, { useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { BsEmojiDizzyFill } from "react-icons/bs";
import { TEMPLATESPAGES } from "../../Routes/navigation/navigationPaths";
import { Link } from "react-router-dom";
import Logo from "../../assets/LogoHubForge.png";
import { CardesTemplate } from "./CardesTemplate";
import DefaltProfile from "../../assets/userLogo.jpg";
import SelectCategory from "./SelectCategory";
import { useGetTemplatesQuery } from "../../services/templates_services/TemplateService";

const TemplateComponent = ({ display }) => {
  const [activeCat, setActiveCat] = useState("");
  const { data: templates } = useGetTemplatesQuery(activeCat);
  const { apiUrlImg } = useAppContext();

  const handleSelectCategory = (categoryId) => {
    setActiveCat(categoryId);
  };

  const getCreator = (template) => {
    if (template.user_role === "bakeliste") {
      return {
        creator: template.user_name,
        profile:
          template.user_profile === null
            ? DefaltProfile
            : `${apiUrlImg}/${template.user_profile}`,
      };
    } else {
      return { creator: "Bakeli Work", profile: Logo };
    }
  };

  return (
    <div className="w-full">
      {/* <CategoriesList
          onSelectCategory={ClicCategory}
          selectedCategoryId={activeCat}
          id="templates"
        />{" "} */}
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-base font-inter text-center text-graymat w-[75%] uppercase">
          Nos templates
        </h1>
        <h1 className="text-[2.8rem] max-xl:text-4xl max-lg:text-xl antialiased font-semibold font-inter text-noirel max-sm:text-lg text-center w-[75%]">
          Nos templates uniques sont conçus et développés par des Bakelistes.{" "}
        </h1>
        <SelectCategory
          category={activeCat}
          id="templates"
          handleSelectCategory={handleSelectCategory}
        />
        <div className={`w-[88%] ${display}`}>
          {templates?.length > 2 && (
            <div className="flex justify-end">
              <Link
                to={`${TEMPLATESPAGES}`}
                className="w-1/5 flex items-center justify-end gap-2 text-vr"
              >
                <h4 className="text-lg max-xl:text-sm font-semibold font-inter text-noir_200">
                  Tout afficher
                </h4>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center flex-col items-center">
        {templates && templates.length > 0 ? (
          <div
            className={`grid grid-cols-3 gap-8 max-lg:grid-cols-2 max-md:grid-cols-1 w-[88%] mt-5`}
          >
            {templates?.map((template) => {
              const { creator, profile } = getCreator(template);
              return (
                <CardesTemplate
                  key={template.id}
                  HandlePreview={template.aperçu}
                  imageTemplate={`${apiUrlImg}/${template.image}`}
                  fonCardTemplate={"bg-white"}
                  handlePriceTo={template.aperçu}
                  titreCrdTemplate={template.titre}
                  DesctiptionTemplate={template.description}
                  prixTemplate={template.prix === null ? "Free" : template.prix}
                  telechargeLink={`/telecharge/${template?.slug}`}
                  userName={creator}
                  userProfile={profile}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center py-28 gap-3">
            <BsEmojiDizzyFill size={"40px"} />
            <h1 className="text-sm font-sans font-semibold text-center">
              Aucun template disponible pour le moment
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateComponent;
