import React from 'react'
import HeaderDashboard from '../../components/client/dashboardComponents/HeaderDashboard'
import CardProject from '../../components/client/dashboardComponents/CardProject'
// import { BsEmojiDizzyFill } from 'react-icons/bs';
import DashboardPageActor from '../../components/acteurs/DashboardPageActor';
import { selectData } from '../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';

function DashboardClientPage() {
  const user = useSelector(selectData);
  return (
    <div className='grid gap-8 px-5'>
        {user?.statut === "client" ? <div>
          <HeaderDashboard />
          <CardProject/>
        </div>: <div className=''>
        <DashboardPageActor />
      </div>}
    </div>
  )
}

export default DashboardClientPage