// import { FiSettings } from "react-icons/fi";
import img1 from "../../assets/Rectangle 988.png";
import img2 from "../../assets/Rectangle 988 (1).png";
import img3 from "../../assets/Rectangle 988 (2).png";
import img4 from "../../assets/Rectangle 988 (3).png";
import { LuLayoutTemplate } from "react-icons/lu";

export const menuSidebar = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 1024 1024"
      >
        <path
          fill="currentColor"
          d="M448 68.48v64.832A384.128 384.128 0 0 0 512 896a384.13 384.13 0 0 0 378.688-320h64.768A448.128 448.128 0 0 1 64 512A448.13 448.13 0 0 1 448 68.48"
        ></path>
        <path
          fill="currentColor"
          d="M576 97.28V448h350.72A384.064 384.064 0 0 0 576 97.28M512 64V33.152A448 448 0 0 1 990.848 512H512z"
        ></path>
      </svg>
    ),
    text: "Dashboard",
    path: "dashboard",
    dropdown: false,
    userStatut: ["client", "coach", "bakeliste"],
  },
];

export const fonctionnalites = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.1em"
        height="1.1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M7.5 16.5h1v-9h-1zm4-5h1v-4h-1zm4 3h1v-7h-1zM4 20V4h16v16zm1-1h14V5H5zm0 0V5z"
        ></path>
      </svg>
    ),
    text: "Suivi Projets",
    path: "projects",
    dropdown: true,
    userStatut: ["coach", "bakeliste"],
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M2 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-6m8-3V4M8 8H3"
        ></path>
      </svg>
    ),
    text: "Voir livrables",
    path: "livrable",
    dropdown: false,
    userStatut: ["coach", "bakeliste"],
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M13 14H9a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2m4-10h-1.18A3 3 0 0 0 13 2h-2a3 3 0 0 0-2.82 2H7a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m-7 1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4Zm8 14a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V6h1a1 1 0 0 1 1 1Zm-3-9H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2"
        ></path>
      </svg>
    ),
    text: "Historique tâches ",
    path: "taches",
    dropdown: false,
    userStatut: ["coach", "bakeliste"],
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M432 64H16v320h416Zm-32 288H48V96h352Z"
        ></path>
        <path fill="currentColor" d="M464 144v272H96v32h400V144z"></path>
        <path
          fill="currentColor"
          d="M224 302.46c39.7 0 72-35.137 72-78.326s-32.3-78.326-72-78.326s-72 35.136-72 78.326s32.3 78.326 72 78.326m0-124.652c22.056 0 40 20.782 40 46.326s-17.944 46.326-40 46.326s-40-20.782-40-46.326s17.944-46.326 40-46.326M80 136h32v176H80zm256 0h32v176h-32z"
        ></path>
      </svg>
    ),
    text: "Historique paiements",
    path: "paiement",
    dropdown: false,
    userStatut: ["coach", "bakeliste"],
  },
];

export const pages = [
  {
    icon: <LuLayoutTemplate />,
    text: "Templates",
    path: "mesTemplates",
    dropdown: false,
    userStatut: ["bakeliste"],
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          color="currentColor"
        >
          <path d="M15.5 12a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0"></path>
          <path d="M21.011 14.097c.522-.141.783-.212.886-.346c.103-.135.103-.351.103-.784v-1.934c0-.433 0-.65-.103-.784s-.364-.205-.886-.345c-1.95-.526-3.171-2.565-2.668-4.503c.139-.533.208-.8.142-.956s-.256-.264-.635-.479l-1.725-.98c-.372-.21-.558-.316-.725-.294s-.356.21-.733.587c-1.459 1.455-3.873 1.455-5.333 0c-.377-.376-.565-.564-.732-.587c-.167-.022-.353.083-.725.295l-1.725.979c-.38.215-.57.323-.635.48c-.066.155.003.422.141.955c.503 1.938-.718 3.977-2.669 4.503c-.522.14-.783.21-.886.345S2 10.6 2 11.033v1.934c0 .433 0 .65.103.784s.364.205.886.346c1.95.526 3.171 2.565 2.668 4.502c-.139.533-.208.8-.142.956s.256.264.635.48l1.725.978c.372.212.558.317.725.295s.356-.21.733-.587c1.46-1.457 3.876-1.457 5.336 0c.377.376.565.564.732.587c.167.022.353-.083.726-.295l1.724-.979c.38-.215.57-.323.635-.48s-.003-.422-.141-.955c-.504-1.937.716-3.976 2.666-4.502"></path>
        </g>
      </svg>
    ),
    text: "Paramètres",
    path: "parameter",
    dropdown: false,
    userStatut: ["client", "coach", "bakeliste"],
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        // className="cursor-pointer"
        viewBox="0 0 24 24"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M18.134 11C18.715 16.375 21 18 21 18H3s3-2.133 3-9.6c0-1.697.632-3.325 1.757-4.525S10.41 2 12 2q.507 0 1 .09M19 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6m-5.27 13a2 2 0 0 1-3.46 0"
        ></path>
      </svg>
    ),
    text: "Notifications",
    path: "parameter",
    dropdown: false,
    userStatut: ["client", "coach", "bakeliste"],
  },
];

export const cardData = [
    {id: 1, img: img1, title: "Easy Funding", objectif: "Création contenu web", budget: "50000", status: "En cours"},
    {id: 2, img: img2, title: "Bakeli Work", objectif: "Création contenu web", budget: "100000", status: "Terminé"},
    {id: 3, img: img3, title: "Fewnu", objectif: "Création contenu web", budget: "40000", status: "En pause"},
    {id: 4, img: img4, title: "VolkenoBook", objectif: "Création contenu web", budget: "50000", status: "Terminé"},
]

export const TabsMenu = [
    {text: "Tous", bordure: "border-e-2 border-gray-300 text-orange"},
    {text: "En cours", bordure: "border-e-2 border-gray-300"},
    {text: "Terminé", bordure: "border-e-2 border-gray-300"},
    {text: "En pause"},
]

export const menuDetailProjet = [
    {text: "Projet", path: "detail-projet"},
    {text: "Données financières", path: "donnees-financieres-projet"},
    {text: "Documents", path: "document-projet"},
]