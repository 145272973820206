import React from 'react';
import { IoIosShareAlt } from 'react-icons/io';
import { MdDeleteForever, MdModeEditOutline } from 'react-icons/md';
import { PulseLoader } from 'react-spinners';


const CommentOptions = ({
  commentId,
  commentText,
  handleEdit,
  handleDelete,
  isLoadingDeleteComment,
  handleReply,
  isParent,
  toggleReplies,
  showReplies,
  reply,
  user,
  elem,
  background,
}) => {
  return (
    <div
      className={`flex gap-2 ${background} text-blueclear w-full h-full`}
      style={{ pointerEvents: "auto", zIndex: 1000 }}
    >
      {user?.id === elem && (
        <div
          className="flex gap-4 items-center cursor-pointer tooltip"
          data-tip="Modifier"
          onClick={handleEdit}
        >
          <MdModeEditOutline size={"17px"} />
        </div>
      )}
      {user?.id === elem && (
        <div
          className="flex gap-4 items-center cursor-pointer tooltip"
          data-tip="Supprimer"
          onClick={() => handleDelete(commentId, isParent)}
        >
          {isLoadingDeleteComment ? (
            <PulseLoader size={4} color="red" />
          ) : (
            <MdDeleteForever size={"20px"} className="text-red" />
          )}
        </div>
      )}
      {!isParent && !reply && (
        <div
          className="flex gap-4 items-center cursor-pointer tooltip"
          data-tip="Répondre"
          onClick={() => handleReply(commentId)}
        >
          <IoIosShareAlt className="" size={"17px"} />
        </div>
      )}
    </div>
  );
};

export default CommentOptions;