import React, { useState } from "react";
import toast from "react-hot-toast";
import { BsArrowReturnRight } from "react-icons/bs";
import { IoIosSend } from "react-icons/io";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { selectData } from "../../../../redux/features/userSliceWithTokenValidation";
import { ProjectsEndpoints } from "../../../../Routes/api/Endpoints";
import {
  useGetDataQuery,
  usePostDataMutation,
} from "../../../../services/auth-services/AuthService";
import MonInput from "../../../Utils/design-system/inputs/MonInput";
import { formatDateComplete } from "../../../Utils/FormatDate";
import MonBouton from "../../../Utils/design-system/buttons/MonBouton";

const CommentLivraison = ({ profileLetters, livraisonId, bakeliste }) => {
  const {
    data: comments,
    refetch: fetchComments,
    isLoading,
  } = useGetDataQuery({
    endpoint: ProjectsEndpoints.getCommentsLivraisons,
    projectId: livraisonId,
  });
  const [postData, { isLoading: isLoadingComment }] = usePostDataMutation();
  const user = useSelector(selectData);
  const [showComments, setShowReplies] = useState(false);
  const [comment, setComment] = useState("");
  const handleSubmitComment = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("message", comment);
    formData.append("commentateur_id", user?.id);

    postData({
      endpoint: ProjectsEndpoints.postLivraisonComment,
      elementID: livraisonId,
      formData,
    })
      .unwrap()
      .then((result) => {
        setComment("");
        fetchComments();
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <>
      <div className="w-full pt-6">
        <div className="flex gap-2 items-center">
          {/* <IoList size={"25px"} /> */}
          <h3 className="text-noir_300 text-xl font-bold">Commentaires</h3>
          <span className="text-xl font-normal text-grys">
            ({comments?.length ? comments?.length : 0})
          </span>
        </div>
      </div>
      {/* {comments?.length > 0 && (
        <div
          className="flex gap-4 pt-3 cursor-pointer"
          onClick={() => setShowReplies(!showComments)}
        >
          <BsArrowReturnRight size={"14px"} />
          <span className="text-xs font-sans font-semibold flex items-center">
            {showComments
              ? "Masquez les masquez"
              : "Afficher les commentaires..."}
          </span>
        </div>
      )} */}
      {/* {showComments && ( */}
        <div className="w-full py-6 flex flex-col gap-6">
          {isLoading ? (
            <div>Chargement en cours...</div>
          ) : (
            comments &&
            comments.length > 0 &&
            comments.map((comment) => {
              const firstLetters = profileLetters(comment?.commentateur?.name);
              return (
                <div className="flex gap-2 items-center border-b border-gryborder/80 w-full">
                  <div className="text-xs leading-5 w-full">
                    <div className="flex justify-between w-full">
                      <div className="flex items-center gap-2">
                        <span className="font-inter text-[13px] font-semibold text-orange">
                          {comment?.commentateur?.name === bakeliste
                            ? "Moi"
                            : comment?.commentateur?.name}
                        </span>
                        <span className="text-xs font-inter text-noir_300">
                          a commenté
                        </span>
                      </div>
                      <div className="text-xs font-medium text-noir_300 font-inter">
                        {formatDateComplete(comment.created_at)}
                      </div>
                    </div>
                    <div>
                      <span className="font-inter text-[14px] font-light">
                        {comment.message}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      {/* )} */}
      <div className="w-full flex flex-col gap-3">
        <MonInput
          type={"text"}
          id={"commentaire"}
          name={"commentaire"}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={"Ajouter un commentaire..."}
          paddingB={
            "w-full bg-white pt-4 font-inter font-medium !px-2 pb-12 flex-wrap shadow-none rounded-md border border-gryborder/70 text-sm"
          }
          required={false}
        />
        <div className="inline-flex justify-end items-center w-full">
          <MonBouton
            children={
              isLoadingComment ? (
                <span className="inline-flex items-center gap-2">
                  Envoi <PulseLoader size={4} color="#fff" />
                </span>
              ) : (
                "Envoyer"
              )
            }
            couleurFond={"bg-orange text-white"}
            bordure={
              "w-32 font-inter py-2 justify-center rounded-full text-sm items-center"
            }
            type={"button"}
            action={handleSubmitComment}
          />
        </div>
      </div>
    </>
  );
};

export default CommentLivraison;
