// Util.js
export const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
};
  
export const formatDateComplete = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
};

export const formatTime = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleTimeString('fr-FR', options);
};

export const formatDateISO = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const formatDateExcepHours = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
};

export const formatDuration = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    const diff = now - start;

    if (diff < 0 || !startDate) {
      return "";
    }

    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (minutes < 60) {
      return `${minutes} minute(s)`;
    } else if (hours < 24) {
      return `${hours} heure(s)`;
    } else if (days < 30) {
      return `${days} jour(s)`;
    } else if (months < 12) {
      return `${months} mois`;
    } else {
      return `${years} an(s)`;
    }
};

export const formatDateShortMonth = (dateString) => {
  const options = { day: "numeric", month: "short" }; 
  const date = new Date(dateString);

  // Format personnalisé pour les mois (en français)
  const localeDate = date.toLocaleDateString("fr-FR", options);
  const formattedDate = localeDate.replace(".", ""); 
  return formattedDate;
};