import React, { useState, useEffect, useRef } from 'react';
import ProjectColumn from './ProjectColumn';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
// import { IoFilterOutline } from 'react-icons/io5';
import Filter from '../../Utils/Filter';
import { handleFilterChange } from '../../Utils/LogiqueFilter';
// import ProjectMember from './ProjectMember';
import { ProjectsEndpoints } from '../../../Routes/api/Endpoints';
import { selectData } from '../../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useFetchProjectsQuery, useGetDataQuery, usePostDataMutation } from '../../../services//auth-services/AuthService';
import ProjectHeader from './ProjectHeader';

function ProjectSpace() {
  const { data: projets, refetch } = useFetchProjectsQuery(ProjectsEndpoints.getProjects);;
  const { slug } = useParams();
  const filteredProjects = projets?.filter((proj) => proj.slug === slug);
  const [ updateTask ] = usePostDataMutation()
  const taches = filteredProjects && filteredProjects.length > 0 ? filteredProjects[0]?.taches : [];
  const rejoignants = filteredProjects && filteredProjects.length > 0 ? filteredProjects[0]?.participants_ids : []
  const projectName = filteredProjects?.find((proj) => proj.titre)
   const [columnStates, setColumnStates] = useState({
     AFAIRE: false,
     ENCOURS: false,
     TERMINE: false,
     ENREVUE: false,
     ACCEPTEE: false,
   });
  const { data: postulants, isLoading: loadingPostulants, refetch: refresh } = useGetDataQuery({
    endpoint: ProjectsEndpoints.getProjectMembers,
    projectId: projectName?.id
  });
  const tachesRef = useRef(taches);
  const user = useSelector(selectData)
  // console.log(postulants);
  
  const [columns, setColumns] = useState({
    AFAIRE: { title: 'À faire', statut: "A_FAIRE", backGround: 'border-gray-400/70 text-black', numberTask: taches['A_FAIRE']?.length || '', taches: taches['A_FAIRE'] || [] },
    ENCOURS: { title: 'En cours', statut: "EN_COURS", backGround: 'border-orange', numberTask: taches['EN_COURS']?.length || '', taches: taches['EN_COURS'] || [] },
    TERMINE: { title: 'Terminé', statut: "TERMINEE", backGround: 'border-lime-400', numberTask: taches['TERMINEE']?.length || '', taches: taches['TERMINEE'] || [] },
    ENREVUE: { title: 'En revue', statut: "EN_REVUE", backGround: 'border-blue-500', numberTask: taches['EN_REVUE']?.length || '', taches: taches['EN_REVUE'] || [] },
    ACCEPTEE: { title: 'Accepté', statut: "ACCEPTEE", backGround: 'border-vr', numberTask:taches['ACCEPTEE']?.length || '', taches: taches['ACCEPTEE'] || [] },
  });

  const getFirstLetters = (str) => {
    return str?.split(' ').map(word => word[0]).join('');
  };
  

  useEffect(() => {
    if (JSON.stringify(tachesRef.current) !== JSON.stringify(taches)) {
      tachesRef.current = taches;
      setColumns({
        AFAIRE: { title: 'À faire', statut: "A_FAIRE", backGround: 'border-red', numberTask: taches['A_FAIRE']?.length || '', taches: taches['A_FAIRE'] || [] },
        ENCOURS: { title: 'En cours', statut: "EN_COURS", backGround: 'border-orange', numberTask: taches['EN_COURS']?.length || '', taches: taches['EN_COURS'] || [] },
        TERMINE: { title: 'Terminé', statut: "TERMINEE", backGround: 'border-lime-400	', numberTask: taches['TERMINEE']?.length || '', taches: taches['TERMINEE'] || [] },
        ENREVUE: { title: 'En revue', statut: "EN_REVUE", backGround: 'border-blue-500', numberTask: taches['EN_REVUE']?.length || '', taches: taches['EN_REVUE'] || [] },
        ACCEPTEE: { title: 'Accepté', statut: "ACCEPTEE", backGround: 'border-vr ', numberTask:taches['ACCEPTEE']?.length || '', taches: taches['ACCEPTEE'] || [] },
      });
      setColumnStates({
        AFAIRE: false,
        ENCOURS: false,
        TERMINE: false,
        ENREVUE: false,
        ACCEPTEE: false,
      });
    }
  }, [taches]);

  const handleFilterChangeWrapper = (statut) => {
    handleFilterChange(columns, setColumns, taches, statut);
  };

  const onDragEnd = (result) => {
    const { destination, source, type } = result;
  
    if (!destination) return;
    
    if (type === 'column') {
      const columnOrder = Array.from(Object.keys(columns));
      const [removed] = columnOrder.splice(source.index, 1);
      columnOrder.splice(destination.index, 0, removed);
  
      const newColumns = {};
      columnOrder.forEach((key) => {
        newColumns[key] = columns[key];
      });
  
      setColumns(newColumns);
      return;
    }
  
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
  
    if (!sourceColumn || !destColumn) return;
  
    const sourceItems = Array.from(sourceColumn.taches);
    const [removed] = sourceItems.splice(source.index, 1);
  
    if (removed.assigned_to_id === null && user.statut !== "coach") {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          taches: sourceItems,
        },
      });
    } else {
      const destItems = Array.from(destColumn.taches);
      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          taches: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          taches: destItems,
        },
      });
      const formData = new FormData()
      formData.append('etat', destColumn.statut)
      
      updateTask({ endpoint: ProjectsEndpoints.updateTask, elementID: removed?.id, formData })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.log(error);  
      });
    }
  };

  const toggleColumnClose = (columnKey) => {
    setColumnStates((prevStates) => ({
      ...prevStates,
      [columnKey]: !prevStates[columnKey],
    }));
  };
  
  return (
    <div className="flex flex-col px-6">
      <ProjectHeader
        profileLetters={getFirstLetters}
        members={postulants?.rejoignants}
        projectId={projectName?.id}
        projectParticipants={rejoignants}
        refreshData={refetch}
        options={postulants?.rejoignants}
        loader={loadingPostulants}
        page={"Dashboard"}
        projectName={projectName?.titre}
        user={user}
      />
      <div className=" pt-1 pb-2 flex justify-between">
        <div className="font-inter text-lg text-noir_300">
          Liste des tâches du projet{" "}
          <span className="text-md font-sans font-bold text-blk">
            {projectName?.titre}
          </span>
        </div>
        {/* <Filter /> */}
      </div>
      <div className="overflow-x-scroll scrollbar-none max-h-[85vh] overflow-y-hidden flex-grow">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="ROOT" direction="horizontal" type="column">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex gap-3 py-3 min-w-full w-max max-md:w-auto"
              >
                {Object.keys(columns).map((columnId, index) => {
                  const column = columns[columnId];
                  return (
                    <Draggable
                      draggableId={columnId}
                      key={columnId}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${
                            columnStates[columnId] && "!min-w-[50px]"
                          }`}
                        >
                          <ProjectColumn
                            title={column.title}
                            backGround={column.backGround}
                            id={columnId}
                            taches={column.taches}
                            numberTask={column?.numberTask}
                            statut={column.statut}
                            refetch={refetch}
                            profileLetters={getFirstLetters}
                            onClose={columnStates[columnId]}
                            setOnClose={() => toggleColumnClose(columnId)}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}
export default ProjectSpace;