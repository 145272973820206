import React, { useState } from 'react'
import { IoClose} from 'react-icons/io5';
import ModalElementContent from './ModalElementContent';
// import DateSelector from '../client/projectsComponents/DateSelector';

const ModalElement = ({onClose, membres, loadingMember, isLoading, handleAssign, profileLetters, checkMember, handleSelectedMember, options, isMember, titleModal, memberAssigned,
  modalForDate, participants
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        setIsVisible(false);
        setTimeout(() => onClose(), 300); 
      }
    };
    
  return (
    <div className={`fixed ${checkMember && "bg-black/10 h-full mt-0 justify-end items-center"} ${modalForDate && "items-center !justify-start left-52"} bg-black/10 inset-0 flex h-full justify-end  z-[4000] transition-opacity duration-300 `}
    onClick={handleOverlayClick}
    >
      <div className={`bg-white_100 shadow px-6 h-full  ${checkMember && "w-[31%] h-full"} ${modalForDate && "w-[25%] !h-max  content"} content scrollbar-none overflow-y-scroll w-[31%] max-lg:overflow-y-auto transform transition-all duration-300`}>
        {checkMember && <div className='flex justify-between items-center py-4'>
          <h3 className='text-xl font-inter text-noir_300 font-black'>Membres du projet</h3>
          <IoClose className='text-xl cursor-pointer'
            onClick={() => {
              setIsVisible(false);
              setTimeout(() => onClose(), 300);
            }}
          />
        </div>}
        <div className=''>
          <ModalElementContent members={membres}
              isLoading={isLoading}
              loadingMember={loadingMember}
              titleModal={titleModal}
              handleAssign={handleAssign}
              profileLetters={profileLetters}
              options={options}
              handleSelectedMember={handleSelectedMember}
              isMember={isMember}
              memberAssigned={memberAssigned}
              checkMember={checkMember}
              participants={participants}
            />
        </div>
      </div>
    </div>
  )
}

export default ModalElement
